import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Offer() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Complete Crypto Project</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    We offer a comprehensive crypto project package, including a professional main project website and a custom token built on the BNB Smart Chain (BSC) network. Our service also includes dedicated websites for managing airdrops and presales, ensuring smooth token distribution and community engagement.
                                </div>
                                <br/>
                                <div>
                                    With expert integration and secure smart contracts, we provide a seamless launch experience. Start your crypto journey with a fully developed ecosystem ready for success.
                                </div>
                                <br />
                                <div className="text-center">
                                  <h2 style={{color: 'yellow'}}>"Complete Crypto Project Package: Website, BSC Token, Airdrop & Presale Solutions"</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>What You Will Get</h1>
                                </div>
                                <br/>
                                <div><h3>- Main Project Website (That Earn Money).</h3></div>
                                <div><h3>- Token on BNB Smart Chain.</h3></div>
                                <div><h3>- Airdrop Website (With Affiliate Program).</h3></div>
                                <div><h3>- Presale Website (With Affiliate Program).</h3></div>
                                <div><h3>- Swap Website (Trade your token with BNB).</h3></div>
                                <br/>
                                <div><h3>- Main Website Demo : <a href="https://0xfactory.xyz/" target="_blank" rel="noreferrer">https://0xfactory.xyz</a></h3></div>
                                <div><h3>- Presale Demo : <a href="https://launchpadplus.0xfactory.com/" target="_blank" rel="noreferrer">https://launchpadplus.0xfactory.com</a></h3></div>
                                <div><h3>- Airdrop Demo : <a href="https://airdrop2.0xfactory.com/" target="_blank" rel="noreferrer">https://airdrop2.0xfactory.com</a></h3></div>
                                <div><h3>- Swap Demo : <a href="https://swap.0xfactory.com/" target="_blank" rel="noreferrer">https://swap.0xfactory.com</a></h3></div>
                                <br/>
                                <h4><h3>Price : $200</h3></h4>
                                <br/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us</h3></a>
                                </div>
                                <br/>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/offer.png" width={"100%"} alt="Social Image" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}
